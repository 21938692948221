<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Liquidaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title">
                      Formulario {{ accion }} Liquidaciones
                    </h5>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-2" v-if="form.id">
                          <label># Liquidación</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.id"
                            disabled
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label>Fecha Inicial</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.fecha_ini"
                            :class="
                              $v.form.fecha_ini.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validaFechasForm"
                            :disabled="editar == 1"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label>Fecha Final</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.fecha_fin"
                            :class="
                              $v.form.fecha_fin.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validaFechasForm"
                            :disabled="!form.fecha_ini || editar == 1"
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label>Empresa</label>
                          <v-select
                            :class="[
                              $v.form.empresa_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="empresa"
                            placeholder="Empresas"
                            label="razon_social"
                            :options="listasForms.empresas"
                            class="form-control form-control-sm p-0"
                            @input="getSelectEmpresas()"
                            :disabled="editar == 1"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Bloque</label>
                          <v-select
                            :class="[
                              $v.form.bloque_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="bloque"
                            placeholder="Bloques"
                            label="nombre"
                            :options="listasForms.bloques"
                            class="form-control form-control-sm p-0"
                            @input="getSelectBloques()"
                            :disabled="editar == 1"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Operación</label>
                          <v-select
                            :class="[
                              $v.form.tif_operacion_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="tif_operacion"
                            placeholder="Operación"
                            label="Noperacion"
                            :options="listasForms.operaciones"
                            class="form-control form-control-sm p-0"
                            :disabled="!form.bloque_id || editar == 1"
                            @input="getSelectOperaciones()"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Producto</label>
                          <v-select
                            :class="[
                              $v.form.producto_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="producto"
                            placeholder="Productos"
                            label="nombre"
                            :options="listasForms.productos"
                            class="form-control form-control-sm p-0"
                            @input="getSelectProductos()"
                            :disabled="!form.tif_operacion_id || editar == 1"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Tipo Tarifa</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.tipo_tarifa"
                            :class="
                              $v.form.tipo_tarifa.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="editar == 1"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tarifa in listasForms.tipo_tarifas"
                              :key="tarifa.numeracion"
                              :value="tarifa.numeracion"
                            >
                              {{ tarifa.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-2">
                          <label for="Volumen">Volumen</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.volumen"
                            disabled="disabled"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label>Estado</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.estado"
                            :class="
                              $v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @change="save()"
                            :disabled="
                              !$store.getters.can(
                                'tif.liquidaciones.cambioEstado'
                              ) && guardar == 2
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                              :disabled="
                                estado.numeracion === 2 &&
                                $route.params.accion == 'Crear'
                              "
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-12">
                          <label>Observación</label>
                          <textarea
                            class="form-control form-control-sm"
                            v-model="form.observacion"
                            :class="
                              $v.form.observacion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-if="
                      guardar == 1 &&
                      !$v.form.$invalid &&
                      $store.getters.can('tif.liquidaciones.edit')
                    "
                    @click="validarLiquidacion()"
                    :disabled="form.estado == 2"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    v-else-if="
                      guardar == 2 &&
                      !$v.form.$invalid &&
                      $store.getters.can('tif.liquidaciones.edit')
                    "
                    @click="save()"
                    :disabled="form.estado == 2"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="detLiquidaciones !== null && detLiquidaciones !== undefined"
            >
              <div class="card" v-if="detLiquidaciones.length > 0">
                <div class="card-header pt-2 pb-2 bg-frontera text-white">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-2">
                        <h5 class="mb-0">Detalle</h5>
                      </div>
                      <div class="col-md-10">
                        <div class="btn-group float-right">
                          <button
                            class="btn bg-info"
                            @click="save()"
                            :disabled="form.estado == 2"
                          >
                            <i class="fas fa-sync-alt"></i><br />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <table
                        v-if="form.tipo_tarifa == 1"
                        class="table table-bordered table-striped table-hover table-responsive table-sm"
                        style="font-size: 90%"
                      >
                        <thead>
                          <tr>
                            <th align="center" rowspan="2">Vehículo</th>
                            <th rowspan="2">Bloque</th>
                            <th rowspan="2">Tipo vehiculo</th>
                            <th rowspan="2">Turno</th>
                            <th colspan="2">T1</th>
                            <th colspan="2">T2</th>
                            <th colspan="2">T3</th>
                            <th colspan="2">T4</th>
                            <th colspan="2">T5</th>
                            <th colspan="2">T6</th>
                            <th colspan="2" align="center">Operativo</th>
                            <th colspan="3" align="center">Disponible</th>
                            <th colspan="2" align="center">Indisponible</th>
                            <th colspan="3" align="center">Totales</th>
                            <th rowspan="2" align="center">Valor</th>
                          </tr>
                          <tr>
                            <th>Viajes</th>
                            <th>Valor</th>

                            <th>Viajes</th>
                            <th>Valor</th>

                            <th>Viajes</th>
                            <th>Valor</th>

                            <th>Viajes</th>
                            <th>Valor</th>

                            <th>Viajes</th>
                            <th>Valor</th>

                            <th>Viajes</th>
                            <th>Valor</th>

                            <th>Horas</th>
                            <th>Fraccion</th>

                            <th>Horas</th>
                            <th>Fraccion</th>
                            <th>Valor</th>

                            <th>Horas</th>
                            <th>Fracción</th>

                            <th>Viajes</th>
                            <th>Horas</th>
                            <th>Fraccion</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="detLiqui in detLiquidaciones"
                            :key="detLiqui.id"
                          >
                            <td>{{ detLiqui.vehiculo.placa }}</td>
                            <td v-if="bloque !== null && bloque != undefined">
                              {{ bloque.nombre }}
                            </td>
                            <td>
                              {{ detLiqui.vehiculo.tipo_vehiculo.nombre }}
                            </td>

                            <td>{{ detLiqui.turno.nombre }}</td>

                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T1-viaje"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T1-valor"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T2-viaje"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T2-valor"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T3-viaje"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T3-valor"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T4-viaje"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T4-valor"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T5-viaje"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T5-valor"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T6-viaje"] }}
                            </td>
                            <td
                              v-if="
                                arrayTotalPozo !== null &&
                                arrayTotalPozo !== undefined
                              "
                            >
                              {{ arrayTotalPozo[detLiqui.id + "-T6-valor"] }}
                            </td>

                            <td>
                              {{ (detLiqui.tiempo_operativo / 60).toFixed(2) }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.cant_operativo).toFixed(2)
                              }}
                            </td>

                            <td>
                              {{ (detLiqui.tiempo_disponible / 60).toFixed(2) }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.cant_disponible).toFixed(2)
                              }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.valor_disponible).toFixed(2)
                              }}
                            </td>

                            <td>
                              {{
                                (detLiqui.tiempo_indisponible / 60).toFixed(2)
                              }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.cant_indisponible).toFixed(
                                  2
                                )
                              }}
                            </td>

                            <td>{{ detLiqui.tot_cant_viajes }}</td>
                            <td>
                              {{
                                (
                                  (detLiqui.tiempo_operativo +
                                    detLiqui.tiempo_disponible +
                                    detLiqui.tiempo_indisponible) /
                                  60
                                ).toFixed(2)
                              }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.cant_operativo) +
                                parseFloat(detLiqui.cant_disponible) +
                                parseFloat(detLiqui.cant_indisponible)
                              }}
                            </td>
                            <td>
                              {{ parseFloat(detLiqui.valor_total).toFixed(2) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        v-if="form.tipo_tarifa == 2"
                        class="table table-bordered table-striped table-hover table-sm"
                        style="font-size: 90%"
                      >
                        <thead>
                          <tr>
                            <th align="center" rowspan="2">Vehículo</th>
                            <th rowspan="2">Bloque</th>
                            <th rowspan="2">Tipo vehiculo</th>
                            <th rowspan="2">Turno</th>
                            <th rowspan="2"># Viaje</th>
                            <th colspan="3" align="center">Operativo</th>
                            <th colspan="2" align="center">Indisponible</th>
                            <th colspan="2" align="center">Kilometros</th>
                            <th colspan="3" align="center">Total</th>
                          </tr>
                          <tr>
                            <th>Horas</th>
                            <th>Fraccion</th>
                            <th>Costo Tarifa Fija</th>

                            <th>Horas</th>
                            <th>Fracción</th>

                            <th>Cantidad</th>
                            <th>Costo tarifa variable</th>

                            <th>Horas</th>
                            <th>Fracción</th>
                            <th>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="detLiqui in detLiquidaciones"
                            :key="detLiqui.id"
                          >
                            <td>
                              <button
                                type="button"
                                class="btn btn-sm btn-default py-0"
                                data-toggle="modal"
                                data-target="#modal-form-placaTurno"
                                @click="
                                  llenarModalDetPlaca(
                                    detLiqui.tif_liquidacion_id,
                                    detLiqui.vehiculo_id,
                                    detLiqui.turno_id,
                                    detLiqui.tipo_vehiculo_id
                                  )
                                "
                              >
                                {{ detLiqui.vehiculo.placa }}
                              </button>
                            </td>
                            <td v-if="bloque !== null && bloque != undefined">
                              {{ bloque.nombre }}
                            </td>
                            <td>
                              {{ detLiqui.vehiculo.tipo_vehiculo.nombre }}
                            </td>
                            <td>{{ detLiqui.turno.nombre }}</td>
                            <td>{{ detLiqui.tot_cant_viajes }}</td>

                            <td>
                              {{ (detLiqui.tiempo_operativo / 60).toFixed(2) }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.cant_operativo).toFixed(2)
                              }}
                            </td>
                            <td>{{ detLiqui.valorFijo }}</td>
                            <td>
                              {{
                                (detLiqui.tiempo_indisponible / 60).toFixed(2)
                              }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.cant_indisponible).toFixed(
                                  2
                                )
                              }}
                            </td>
                            <td>
                              {{ parseFloat(detLiqui.tot_km).toFixed(2) }}
                            </td>
                            <td>{{ detLiqui.valorVariable }}</td>
                            <td>
                              {{
                                (
                                  (detLiqui.tiempo_operativo +
                                    detLiqui.tiempo_indisponible) /
                                  60
                                ).toFixed(2)
                              }}
                            </td>
                            <td>
                              {{
                                detLiqui.fracDetTotalFv
                                  ? detLiqui.fracDetTotalFv.toFixed(2)
                                  : ""
                              }}
                            </td>
                            <td>{{ detLiqui.valorTotal }}</td>
                          </tr>
                          <tr>
                            <th colspan="4" style="font-size: 18px">Total:</th>
                            <td>{{ totalDet.turnosCant }}</td>
                            <td>{{ totalDet.horaOp }}</td>
                            <td>{{ totalDet.fracOp }}</td>
                            <td>{{ valorTarFijo }}</td>
                            <td>{{ totalDet.horaIn }}</td>
                            <td>{{ totalDet.fracIn }}</td>
                            <td>{{ totalDet.totKm }}</td>
                            <td>{{ valorTarVar }}</td>
                            <td>
                              {{ parseFloat(totalDet.horaTotal).toFixed(2) }}
                            </td>
                            <td>{{ totalDet.fracTotal }}</td>
                            <td>{{ fracValorTotal }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        v-if="form.tipo_tarifa == 3"
                        class="table table-bordered table-striped table-hover table-sm"
                        style="font-size: 90%"
                      >
                        <thead>
                          <tr>
                            <th align="center" rowspan="2">Vehículo</th>
                            <th rowspan="2">Bloque</th>
                            <th rowspan="2">Tipo vehiculo</th>
                            <th rowspan="2">Turno</th>
                            <th rowspan="2"># Viaje</th>
                            <th colspan="3" align="center">Operativo</th>
                            <th colspan="3" align="center">Disponible</th>
                            <th colspan="2" align="center">Indisponible</th>
                            <th colspan="3" align="center">Total</th>
                          </tr>
                          <tr>
                            <th>Horas</th>
                            <th>Fraccion</th>
                            <th>Valor</th>

                            <th>Horas</th>
                            <th>Fracción</th>
                            <th>Valor</th>

                            <th>Horas</th>
                            <th>Fracción</th>

                            <th>Horas</th>
                            <th>Fracción</th>
                            <th>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(detLiqui, index) in detLiquidaciones"
                            :key="detLiqui.id"
                          >
                            <td>
                              <button
                                type="button"
                                class="btn btn-sm btn-default py-0"
                                data-toggle="modal"
                                data-target="#modal-form-placaTurno"
                                @click="
                                  llenarModalDetPlaca(
                                    detLiqui.tif_liquidacion_id,
                                    detLiqui.vehiculo_id,
                                    detLiqui.turno_id,
                                    detLiqui.tipo_vehiculo_id
                                  )
                                "
                              >
                                {{ detLiqui.vehiculo.placa }}
                              </button>
                            </td>
                            <td v-if="bloque !== null && bloque != undefined">
                              {{ bloque.nombre }}
                            </td>
                            <td>
                              {{ detLiqui.vehiculo.tipo_vehiculo.nombre }}
                            </td>
                            <td>{{ detLiqui.turno.nombre }}</td>
                            <td>{{ detLiqui.tot_cant_viajes }}</td>
                            <td>
                              {{
                                parseFloat(
                                  detLiqui.tiempo_operativo / 60
                                ).toFixed(2)
                              }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.cant_operativo).toFixed(2)
                              }}
                            </td>
                            <td v-if="valores.length > 0">
                              {{ valores[index].costoOperativo }}
                            </td>
                            <td>
                              {{ (detLiqui.tiempo_disponible / 60).toFixed(2) }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.cant_disponible).toFixed(2)
                              }}
                            </td>
                            <td v-if="valores.length > 0">
                              {{ valores[index].costoDisponible }}
                            </td>
                            <td>
                              {{
                                (detLiqui.tiempo_indisponible / 60).toFixed(2)
                              }}
                            </td>
                            <td>
                              {{
                                parseFloat(detLiqui.cant_indisponible).toFixed(
                                  2
                                )
                              }}
                            </td>
                            <td>
                              {{
                                (
                                  (detLiqui.tiempo_operativo +
                                    detLiqui.tiempo_disponible +
                                    detLiqui.tiempo_indisponible) /
                                  60
                                ).toFixed(2)
                              }}
                            </td>
                            <td>
                              {{ detLiqui.fracDetTotalTm.toFixed(2) }}
                            </td>
                            <td>
                              {{ detLiqui.valorTotal }}
                            </td>
                          </tr>
                          <tr>
                            <th colspan="4" style="font-size: 18px">Total:</th>
                            <td>{{ totalDet.turnosCant }}</td>
                            <td>
                              {{ parseFloat(totalDet.horaOp).toFixed(2) }}
                            </td>
                            <td>{{ totalDet.fracOp }}</td>
                            <td>{{ valorTotOp }}</td>
                            <td>
                              {{ parseFloat(totalDet.horaDis).toFixed(2) }}
                            </td>
                            <td>{{ totalDet.fracDis }}</td>
                            <td>{{ valorTotDis }}</td>
                            <td>
                              {{ parseFloat(totalDet.horaIn).toFixed(2) }}
                            </td>
                            <td>{{ totalDet.fracIn }}</td>
                            <td>
                              {{
                                parseFloat(totalDet.horaTiemTotal).toFixed(2)
                              }}
                            </td>
                            <td>
                              {{
                                parseFloat(totalDet.fracTiemTotal).toFixed(2)
                              }}
                            </td>
                            <td>{{ fracValorTotal }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="resumen !== null && resumen !== undefined">
              <div
                class="card"
                v-if="resumen.length > 0 && form.tipo_tarifa == 1"
              >
                <div class="card-header pt-2 pb-2 bg-frontera text-white">
                  <h5 class="mb-0">Resumen</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <table
                        class="table table-bordered table-striped table-hover table-sm"
                        style="font-size: 90%"
                      >
                        <thead>
                          <tr>
                            <th colspan="4">Liquidacion de servicios</th>
                          </tr>
                          <tr>
                            <td><b>TIPOS DE VIAJE</b></td>
                            <td><b>COSTO</b></td>
                            <td><b>CANTIDAD VIAJES</b></td>
                            <td><b>COSTO TOTAL</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="pozo in arrayPozo" :key="pozo.id">
                            <td v-if="pozo['tarifa'] == 'T1'">1</td>
                            <td v-if="pozo['tarifa'] == 'T1'">
                              {{ pozo["tarifa_valor"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T1'">
                              {{ pozo["cantidad_viajes"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T1'">
                              {{ pozo["costo_total"] }}
                            </td>

                            <td v-if="pozo['tarifa'] == 'T2'">2</td>
                            <td v-if="pozo['tarifa'] == 'T2'">
                              {{ pozo["tarifa_valor"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T2'">
                              {{ pozo["cantidad_viajes"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T2'">
                              {{ pozo["costo_total"] }}
                            </td>

                            <td v-if="pozo['tarifa'] == 'T3'">3</td>
                            <td v-if="pozo['tarifa'] == 'T3'">
                              {{ pozo["tarifa_valor"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T3'">
                              {{ pozo["cantidad_viajes"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T3'">
                              {{ pozo["costo_total"] }}
                            </td>

                            <td v-if="pozo['tarifa'] == 'T4'">4</td>
                            <td v-if="pozo['tarifa'] == 'T4'">
                              {{ pozo["tarifa_valor"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T4'">
                              {{ pozo["cantidad_viajes"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T4'">
                              {{ pozo["costo_total"] }}
                            </td>

                            <td v-if="pozo['tarifa'] == 'T5'">5</td>
                            <td v-if="pozo['tarifa'] == 'T5'">
                              {{ pozo["tarifa_valor"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T5'">
                              {{ pozo["cantidad_viajes"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T5'">
                              {{ pozo["costo_total"] }}
                            </td>

                            <td v-if="pozo['tarifa'] == 'T6'">6</td>
                            <td v-if="pozo['tarifa'] == 'T6'">
                              {{ pozo["tarifa_valor"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T6'">
                              {{ pozo["cantidad_viajes"] }}
                            </td>
                            <td v-if="pozo['tarifa'] == 'T6'">
                              {{ pozo["costo_total"] }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" style="text-align: right">TOTAL</td>
                            <td>{{ totalViajes }}</td>
                            <td>{{ tarifaTotal }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-6">
                      <div
                        class="row"
                        v-for="horas in arrayHora"
                        :key="horas.id"
                      >
                        <table
                          class="table table-bordered table-striped table-hover table-sm"
                          style="font-size: 90%"
                        >
                          <thead>
                            <tr>
                              <th colspan="4">
                                Liquidacion de servicios de {{ horas }} horas
                              </th>
                            </tr>
                            <tr>
                              <th>Horas disp</th>
                              <th>Cantidad turnos</th>
                              <th>Costo turno</th>
                              <th>Costo total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="res in resumen" :key="res.id">
                              <td v-if="res.llave == horas">
                                {{
                                  (res.tot_horas_disponibles / 60).toFixed(2)
                                }}
                              </td>
                              <td v-if="res.llave == horas">
                                {{ res.tot_cant_disponible }}
                              </td>
                              <td v-if="res.llave == horas">
                                {{ res.tarifa_disponible }}
                              </td>
                              <td v-if="res.llave == horas">
                                {{ res.costo_total.toFixed(2) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <table
                          class="table table-bordered table-striped table-hover table-sm"
                          style="font-size: 90%"
                        >
                          <thead>
                            <tr>
                              <th colspan="2">COSTO TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>COSTO TOTAL OPERATIVO</td>
                              <td>{{ tarifaTotal }}</td>
                            </tr>
                            <tr>
                              <td>COSTO TOTAL DISPONIBILIDAD</td>
                              <td>{{ totalDisponible }}</td>
                            </tr>
                            <tr>
                              <td>COSTO TOTAL</td>
                              <td>{{ tarifaTotal + totalDisponible }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card"
                v-if="resumen.length > 0 && form.tipo_tarifa == 2"
              >
                <div class="card-header pt-2 pb-2 bg-frontera text-white">
                  <h5 class="mb-0">Resumen</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div
                      class="col-md-6"
                      v-for="horas in arrayHora"
                      :key="horas.id"
                    >
                      <table
                        class="table table-bordered table-striped table-hover table-sm"
                        style="font-size: 90%"
                      >
                        <thead>
                          <tr>
                            <th colspan="8">
                              Liquidacion de servicios de {{ horas }} horas
                            </th>
                          </tr>
                          <tr>
                            <th>Tipo Vehículo</th>
                            <th>Tipo</th>
                            <th># Viajes</th>
                            <th>Turnos</th>
                            <th>Tarifa fija</th>
                            <th>Kms</th>
                            <th>Tarifa Variable</th>
                            <th>Costo</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="det in resumen" :key="det.id">
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 1
                              "
                            >
                              TM
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 1
                              "
                            >
                              OP
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 1
                              "
                            >
                              {{ det.tot_cant_viajes.toFixed(2) }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 1
                              "
                            >
                              {{ det.turnTmOpFv }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 1
                              "
                            >
                              {{ det.tarifaFija }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 1
                              "
                            >
                              {{ parseFloat(det.tot_kilometros).toFixed(2) }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 1
                              "
                            >
                              {{ det.tarifaVariable }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 1
                              "
                            >
                              {{ det.costoTmFvOp }}
                            </td>
                          </tr>
                          <tr v-for="det in resumen" :key="det.id">
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 6
                              "
                            >
                              DT
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 6
                              "
                            >
                              OP
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 6
                              "
                            >
                              {{ det.tot_cant_viajes.toFixed(2) }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 6
                              "
                            >
                              {{ det.turnDtOpFv }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 6
                              "
                            >
                              {{ det.tarifaFija }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 6
                              "
                            >
                              {{ parseFloat(det.tot_kilometros).toFixed(2) }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 6
                              "
                            >
                              {{ det.tarifaVariable }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 6
                              "
                            >
                              {{ det.costoDtFvOp }}
                            </td>
                          </tr>
                          <tr v-for="det in resumen" :key="det.id">
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 7
                              "
                            >
                              SC
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 7
                              "
                            >
                              OP
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 7
                              "
                            >
                              {{ det.tot_cant_viajes.toFixed(2) }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 7
                              "
                            >
                              {{ det.turnScOpFv }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 7
                              "
                            >
                              {{ det.tarifaFija }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 7
                              "
                            >
                              {{ parseFloat(det.tot_kilometros).toFixed(2) }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 7
                              "
                            >
                              {{ det.tarifaVariable }}
                            </td>
                            <td
                              v-if="
                                det.horas == horas && det.tipo_vehiculo_id == 7
                              "
                            >
                              {{ det.costoScFvOp }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!--- Tabla Costo Total -->
                  <div class="row">
                    <div class="col-md-4 offset-4">
                      <table
                        class="table table-bordered table-striped table-hover table-sm"
                        style="font-size: 90%"
                      >
                        <thead>
                          <tr>
                            <th colspan="8">COSTO TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="width: 300px">TM</td>
                            <td style="width: 300px">
                              {{ costoTotFvTm }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 300px">DT</td>
                            <td style="width: 300px">
                              {{ costoTotFvDt }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 300px">SC</td>
                            <td style="width: 300px">
                              {{ costoTotFvSc }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 300px">TOTAL</td>
                            <td>
                              {{ totalLiquiFv }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card"
                v-if="
                  resumen.length > 0 &&
                  form.tipo_tarifa == 3 &&
                  ![52, 53].includes(detLiquidaciones[0].tipo_vehiculo_id)
                "
              >
                <div class="card-header pt-2 pb-2 bg-frontera text-white">
                  <h5 class="mb-0">Resumen</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div
                      class="col-md-6"
                      v-for="horas in arrayHora"
                      :key="horas.id"
                    >
                      <table
                        class="table table-bordered table-striped table-hover table-sm"
                        style="font-size: 90%"
                      >
                        <thead>
                          <tr>
                            <th colspan="5" style="text-align: center">
                              Liquidacion de servicios de {{ horas }}
                            </th>
                          </tr>
                        </thead>
                        <tbody v-for="det in resumen" :key="det.id">
                          <!--tipo vehiculo 1-->
                          <tr
                            v-if="
                              det.horas == horas && det.tipo_vehiculo_id == 1
                            "
                          >
                            <td
                              rowspan="3"
                              style="text-align: center; vertical-align: middle"
                            >
                              <b>TM</b>
                            </td>
                            <td>
                              <b>Tipo</b>
                            </td>
                            <td>
                              <b>Turnos</b>
                            </td>
                            <td>
                              <b>Tarifa</b>
                            </td>
                            <td>
                              <b>Costo</b>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              det.horas == horas && det.tipo_vehiculo_id == 1
                            "
                            class="table-success"
                          >
                            <td>
                              <b>OP</b>
                            </td>
                            <td>
                              {{ det.turnTmOp }}
                            </td>
                            <td>
                              {{ det.tarifaOperativa }}
                            </td>
                            <td>
                              {{ det.costoTmOp }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              det.horas == horas && det.tipo_vehiculo_id == 1
                            "
                            class="table-warning"
                          >
                            <td>
                              <b>DISP</b>
                            </td>
                            <td>
                              {{ det.turnTmDisp }}
                            </td>
                            <td>
                              {{ det.tarifaDisponible }}
                            </td>
                            <td>
                              {{ det.costoTmDisp }}
                            </td>
                          </tr>
                          <!--tipo vehiculo 6-->
                          <tr
                            v-if="
                              det.horas == horas && det.tipo_vehiculo_id == 6
                            "
                          >
                            <td
                              rowspan="3"
                              style="text-align: center; vertical-align: middle"
                            >
                              <b>DT</b>
                            </td>
                            <td>
                              <b>Tipo</b>
                            </td>
                            <td>
                              <b>Turnos</b>
                            </td>
                            <td>
                              <b>Tarifa</b>
                            </td>
                            <td>
                              <b>Costo</b>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              det.horas == horas && det.tipo_vehiculo_id == 6
                            "
                            class="table-success"
                          >
                            <td>
                              <b>OP</b>
                            </td>
                            <td>
                              {{ det.turnDtOp }}
                            </td>
                            <td>
                              {{ det.tarifaOperativa }}
                            </td>
                            <td>
                              {{ det.costoDtOp }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              det.horas == horas && det.tipo_vehiculo_id == 6
                            "
                            class="table-warning"
                          >
                            <td>
                              <b>DISP</b>
                            </td>
                            <td>
                              {{ det.turnDtDisp }}
                            </td>
                            <td>
                              {{ det.tarifaDisponible }}
                            </td>
                            <td>
                              {{ det.costoDtDisp }}
                            </td>
                          </tr>
                          <!--tipo vehiculo 7-->
                          <tr
                            v-if="
                              det.horas == horas && det.tipo_vehiculo_id == 7
                            "
                          >
                            <td
                              rowspan="3"
                              style="text-align: center; vertical-align: middle"
                            >
                              <b>SC</b>
                            </td>
                            <td>
                              <b>Tipo</b>
                            </td>
                            <td>
                              <b>Turnos</b>
                            </td>
                            <td>
                              <b>Tarifa</b>
                            </td>
                            <td>
                              <b>Costo</b>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              det.horas == horas && det.tipo_vehiculo_id == 7
                            "
                            class="table-success"
                          >
                            <td>
                              <b>OP</b>
                            </td>
                            <td>
                              {{ det.turnScOp }}
                            </td>
                            <td>
                              {{ det.tarifaOperativa }}
                            </td>
                            <td>
                              {{ det.costoScOp }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              det.horas == horas && det.tipo_vehiculo_id == 7
                            "
                            class="table-warning"
                          >
                            <td>
                              <b>DISP</b>
                            </td>
                            <td>
                              {{ det.turnScDisp }}
                            </td>
                            <td>
                              {{ det.tarifaDisponible }}
                            </td>
                            <td>
                              {{ det.costoScDisp }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!--- Tabla Costo Total --->
                  <div class="row">
                    <div class="col-md-4 offset-4">
                      <table
                        class="table table-bordered table-striped table-hover table-sm"
                        style="font-size: 90%"
                      >
                        <thead>
                          <tr>
                            <th colspan="8">COSTO TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="width: 300px">TM</td>
                            <td style="width: 300px">
                              {{ costoTotTm }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 300px">DT</td>
                            <td style="width: 300px">
                              {{ costoTotDt }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 300px">SC</td>
                            <td style="width: 300px">
                              {{ costoTotSc }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 300px">TOTAL</td>
                            <td>
                              {{ totalLiqui }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TifLiquidacionesDetPlaca ref="TifLiquidacionesDetPlaca" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import TifLiquidacionesDetPlaca from "../liquidaciones/TifLiquidacionesDetPlaca";
import moment from "moment";
import "moment/locale/es";

export default {
  name: "TifLiquidacionesForm",
  components: {
    Loading,
    vSelect,
    TifLiquidacionesDetPlaca,
  },
  data() {
    return {
      fechaAct: moment().format("YYYY-MM-DD"),
      guardar: 0,
      estados: false,
      cargando: true,
      bloque: {},
      tif_operacion: {},
      liquidacion: {},
      producto: {},
      empresa: {},
      detLiquidaciones: {},
      editar: 0,
      resumen: null,
      arrayHora: [],
      arrayPozo: [],
      arrayTotalPozo: [],
      tarifaTotal: 0,
      totalViajes: 0,
      totalDet: 0,
      totalDisponible: 0,
      totalOperativo: 0,
      valorTarFijo: 0,
      valorTotOp: 0,
      valorTotDis: 0,
      valorTarVar: 0,
      valores: [],
      costoTotTm: 0,
      costoTotFvTm: 0,
      costoTotDt: 0,
      costoTotFvDt: 0,
      costoTotSc: 0,
      costoTotFvSc: 0,
      totalLiqui: 0,
      totalLiquiFv: 0,
      fracValorTotal: 0,
      fracDetTotalTm: 0,
      fracDetTotalFv: 0,
      form: {
        fecha_ini: null,
        fecha_fin: null,
        bloque_id: null,
        producto_id: null,
        tif_operacion_id: null,
        tipo_tarifa: null,
        observacion: null,
        empresa_id: null,
        estado: null,
      },
      accion: "",
      metodo: "",
      listasForms: {
        bloques: [],
        operaciones: [],
        estados: [],
        tipo_tarifas: [],
        productos: [],
        empresas: [],
      },
    };
  },
  validations() {
    return {
      form: {
        fecha_ini: {
          required,
        },
        fecha_fin: {
          required,
        },
        empresa_id: {
          required,
        },
        bloque_id: {
          required,
        },
        producto_id: {
          required,
        },
        tif_operacion_id: {
          required,
        },
        tipo_tarifa: {
          required,
        },
        observacion: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        this.guardar = 2;
        this.liquidacion = await this.$route.params.liquidacion;

        this.form.id = this.liquidacion.id;

        this.bloque = {
          id: this.liquidacion.bloque_id,
          nombre: this.liquidacion.bloque.nombre,
        };

        this.producto = {
          id: this.liquidacion.producto_id,
          nombre: this.liquidacion.producto.nombre,
        };

        this.empresa = {
          id: this.liquidacion.empresa_id,
          razon_social: this.liquidacion.empresa.razon_social,
        };

        this.form.producto_id = this.liquidacion.producto_id;

        this.form.bloque_id = this.bloque.id;

        this.form.empresa_id = this.empresa.id;

        this.tif_operacion = {
          id: this.liquidacion.tif_operacion_id,
          Noperacion: this.liquidacion.tif_operacion.Noperacion,
        };

        this.form.tif_operacion_id = this.tif_operacion.id;
        this.form.fecha_ini = this.liquidacion.fecha_ini;
        this.form.fecha_fin = this.liquidacion.fecha_fin;

        this.form.observacion = this.liquidacion.observacion;
        this.form.tipo_tarifa = this.liquidacion.tipo_tarifa;
        this.form.volumen = this.liquidacion.volumen;
        this.form.estado = this.liquidacion.estado;
        this.editar = 1;

        this.estados = true;
        if (this.form.fecha_fin < this.fechaAct) {
          this.estados = false;
        }
        await this.getDetLiquidacion();
      } else {
        this.metodo = "POST";
        this.guardar = 1;
        this.estados = false;
      }
      this.cargando = false;
    },

    async getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipo_tarifas = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    async getOperacion() {
      axios
        .get("/api/tif/operaciones/lista", {
          params: {
            bloque_id: this.form.bloque_id,
          },
        })
        .then((response) => {
          this.listasForms.operaciones = response.data;
        });
    },

    async validaFechasForm() {
      if (!this.form.fecha_ini) {
        this.form.fecha_fin = null;
        return;
      }

      if (this.form.fecha_ini && this.form.fecha_fin) {
        //Fecha Inicial Liquidación
        var fechaIni = this.form.fecha_ini.split("-");
        var añoActualIni = fechaIni[0];
        var mesActualIni = fechaIni[1];
        //Fecha Final Liquidación
        var fechaFin = this.form.fecha_fin.split("-");
        var añoActualFin = fechaFin[0];
        var mesActualFin = fechaFin[1];
        if (añoActualIni === añoActualFin && mesActualIni === mesActualFin) {
          const fecha_inicial = new Date(this.form.fecha_ini).getTime();
          const fecha_final = new Date(this.form.fecha_fin).getTime();
          if (fecha_inicial > fecha_final) {
            this.form.fecha_fin = null;
            this.$swal({
              icon: "error",
              title: `La fecha final no puede ser menor que la inicial`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        } else {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha final excede el mes de la fecha inicial`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    getSelectBloques() {
      this.form.bloque_id = null;
      this.form.tif_operacion_id = null;
      this.form.producto_id = null;
      this.form.producto_id = null;
      this.tif_operacion = null;
      this.producto = null;
      if (this.bloque) {
        this.form.bloque_id = this.bloque.id;
        this.getOperacion();
      } else {
        this.tif_operacion = null;
        this.listasForms.operaciones = [];
        this.form.tif_operacion_id = null;
        this.form.producto_id = null;
        this.tif_operacion = null;
        this.producto = null;
      }
    },

    getSelectEmpresas() {
      this.form.empresa_id = null;
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    getSelectOperaciones() {
      this.form.tif_operacion_id = null;
      this.form.producto_id = null;
      this.producto = null;
      if (this.tif_operacion) {
        this.form.tif_operacion_id = this.tif_operacion.id;
        this.getProductos();
      } else {
        this.form.producto_id = null;
        this.producto = null;
      }
    },

    validarLiquidacion() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/liquidaciones/validarLiquidacion",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        this.save();
      });
    },

    // Acciones de formularios
    async save() {
      this.cargando = true;
      if (!this.$v.form.$invalid) {
        if (this.validar == "si" && this.guardar == 1) {
          this.$swal({
            icon: "error",
            title: `Ya existe una liquidación con los datos solicitados..!!`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          //this.cargando = false;
        } else {
          await axios({
            method: this.metodo,
            url: "/api/tif/liquidaciones",
            data: this.form,
          })
            .then(async (response) => {
              this.$route.params.liquidacion = response.data;
              this.$route.params.accion = "Editar";
              await this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se actualizó la liquidación correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.editar = 1;
              await this.saveDetLiquidacion();
              await this.getDetLiquidacion();
              //this.cargando = false;
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
      this.cargando = false;
    },

    back() {
      return this.$router.replace("/tif/TifLiquidaciones");
    },

    async getProductos() {
      if (this.form.tif_operacion_id) {
        axios({
          method: "GET",
          url: "/api/tif/operaciones/getProductosHasOp",
          params: this.form.tif_operacion_id,
        }).then((response) => {
          this.listasForms.productos = response.data.productos;
        });
      }
    },

    async getEmpresas() {
      axios({
        method: "GET",
        url: "/api/admin/empresas/lista",
        params: {
          linea_negocio_id: [3],
        },
      }).then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getSelectProductos() {
      this.form.producto_id = null;
      if (this.producto) {
        this.form.producto_id = this.producto.id;
      } else {
        this.form.producto_id = null;
        this.producto = null;
      }
    },

    async saveDetLiquidacion() {
      await axios
        .post("/api/tif/detLiquidaciones", {
          tif_liquidacion_id: this.form.id,
          bloque_id: this.form.bloque_id,
          producto_id: this.form.producto_id,
          tipo_tarifa: this.form.tipo_tarifa,
          tif_operacion_id: this.form.tif_operacion_id,
          fecha_ini: this.form.fecha_ini,
          fecha_fin: this.form.fecha_fin,
        })
        .then((response) => {
          this.detLiquidaciones = response.data;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Error, al crear detalle de liquidacion:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getDetLiquidacion() {
      await axios
        .get("/api/tif/detLiquidaciones", {
          params: {
            tif_liquidacion_id: this.form.id,
          },
        })
        .then(async (response) => {
          this.detLiquidaciones = response.data.detLiquidacion;
          this.resumen = response.data.resumen;
          this.arrayHora = response.data.arrayHora;
          this.arrayPozo = response.data.arrayPozo;
          this.arrayTotalPozo = response.data.arrayTotalPozo;
          this.tarifaTotal = response.data.tarifaTotal;
          this.totalViajes = response.data.totalViajes;
          this.totalDet = response.data.totalDet;
          this.totalDisponible = response.data.totalDisponible;
          this.totalOperativo = response.data.totalOperativo;

          let costoTmOp = 0;
          let costoTmFvOp = 0;
          let costoTmFOp = 0;
          let costoTmVOp = 0;
          let costoTmDisp = 0;
          let costoDtOp = 0;
          let costoDtFvOp = 0;
          let costoDtFOp = 0;
          let costoDtVOp = 0;
          let costoDtDisp = 0;
          let costoScOp = 0;
          let costoScFvOp = 0;
          let costoScFOp = 0;
          let costoScVOp = 0;
          let costoScDisp = 0;

          let costoTotTm = 0;
          let costoTotFvTm = 0;
          let costoTotDt = 0;
          let costoTotFvDt = 0;
          let costoTotSc = 0;
          let costoTotFvSc = 0;

          for (let i = 0; i < this.resumen.length; i++) {
            let tarFija = this.resumen[i].tarifa_fija;
            this.resumen[i].tarifaFija = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              tarFija
            );

            let tarVariable = this.resumen[i].tarifa_variable;
            this.resumen[i].tarifaVariable = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              tarVariable
            );

            let tarOperativa = 0;
            tarOperativa = parseFloat(this.resumen[i].tarifa_op);

            this.resumen[i].tarifaOperativa = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              tarOperativa
            );

            let tarDisponible = 0;
            tarDisponible = this.resumen[i].tarifa_disp;

            this.resumen[i].tarifaDisponible = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              tarDisponible
            );

            if (this.resumen[i].tipo_vehiculo_id == 1) {
              this.resumen[i].turnTmOpFv = parseFloat(
                this.resumen[i].tot_cant_operativo
              );

              this.resumen[i].turnTmOpFv = await this.calculoTurn(
                this.resumen[i].turnTmOpFv
              );

              costoTmFOp =
                this.resumen[i].tarifa_fija * this.resumen[i].turnTmOpFv;
              costoTmVOp =
                this.resumen[i].tarifa_variable *
                this.resumen[i].tot_kilometros;
              costoTmFvOp = parseFloat(costoTmFOp) + parseFloat(costoTmVOp);

              this.resumen[i].costoTmFvOp = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTmFvOp
              );

              if (this.resumen[i].cant_horas != 0) {
                this.resumen[i].turnTmOp = parseFloat(
                  this.resumen[i].tot_tiempo_operativo /
                    this.resumen[i].cant_horas /
                    60
                );
              } else {
                this.resumen[i].turnTmOp = 0;
              }

              this.resumen[i].turnTmOp = await this.calculoTurn(
                this.resumen[i].turnTmOp
              );

              costoTmOp = parseFloat(
                this.resumen[i].tarifa_op * this.resumen[i].turnTmOp
              );

              this.resumen[i].costoTmOp = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTmOp
              );

              if (this.resumen[i].cant_horas != 0) {
                this.resumen[i].turnTmDisp = parseFloat(
                  this.resumen[i].tot_tiempo_disponible /
                    this.resumen[i].cant_horas /
                    60
                );
              } else {
                this.resumen[i].turnTmDisp = 0;
              }

              this.resumen[i].turnTmDisp = await this.calculoTurn(
                this.resumen[i].turnTmDisp
              );

              costoTmDisp =
                this.resumen[i].tarifa_disp * this.resumen[i].turnTmDisp;

              this.resumen[i].costoTmDisp = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTmDisp
              );

              costoTotTm += parseFloat(costoTmOp) + parseFloat(costoTmDisp);

              this.costoTotTm = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTotTm
              );

              costoTotFvTm += parseFloat(costoTmFvOp);
              this.costoTotFvTm = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTotFvTm
              );
            } else if (this.resumen[i].tipo_vehiculo_id == 6) {
              this.resumen[i].turnDtOpFv = parseFloat(
                this.resumen[i].tot_cant_operativo
              );

              this.resumen[i].turnDtOpFv = await this.calculoTurn(
                this.resumen[i].turnDtOpFv
              );

              costoDtFOp =
                this.resumen[i].tarifa_fija * this.resumen[i].turnDtOpFv;

              costoDtVOp =
                this.resumen[i].tarifa_variable *
                this.resumen[i].tot_kilometros;

              costoDtFvOp = parseFloat(costoDtFOp) + parseFloat(costoDtVOp);

              this.resumen[i].costoDtFvOp = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoDtFvOp
              );

              if (this.resumen[i].cant_horas != 0) {
                this.resumen[i].turnDtOp = parseFloat(
                  this.resumen[i].tot_tiempo_operativo /
                    60 /
                    this.resumen[i].cant_horas
                );
              } else {
                this.resumen[i].turnDtOp = 0;
              }

              this.resumen[i].turnDtOp = await this.calculoTurn(
                this.resumen[i].turnDtOp
              );

              costoDtOp = this.resumen[i].tarifa_op * this.resumen[i].turnDtOp;

              this.resumen[i].costoDtOp = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoDtOp
              );

              if (this.resumen[i].cant_horas != 0) {
                this.resumen[i].turnDtDisp = parseFloat(
                  this.resumen[i].tot_tiempo_disponible /
                    60 /
                    this.resumen[i].cant_horas
                );
              } else {
                this.resumen[i].turnDtDisp = 0;
              }

              this.resumen[i].turnDtDisp = await this.calculoTurn(
                this.resumen[i].turnDtDisp
              );

              costoDtDisp =
                this.resumen[i].tarifa_disp * this.resumen[i].turnDtDisp;

              this.resumen[i].costoDtDisp = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoDtDisp
              );

              costoTotDt += parseFloat(costoDtOp) + parseFloat(costoDtDisp);
              this.costoTotDt = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTotDt
              );

              costoTotFvDt += parseFloat(costoDtFvOp);
              this.costoTotFvDt = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTotFvDt
              );
            } else if (this.resumen[i].tipo_vehiculo_id == 7) {
              this.resumen[i].turnScOpFv = parseFloat(
                this.resumen[i].tot_cant_operativo
              );

              this.resumen[i].turnScOpFv = await this.calculoTurn(
                this.resumen[i].turnScOpFv
              );

              costoScFOp =
                this.resumen[i].tarifa_fija * this.resumen[i].turnScOpFv;

              costoScVOp =
                this.resumen[i].tarifa_variable *
                this.resumen[i].tot_kilometros;

              costoScFvOp = parseFloat(costoScFOp) + parseFloat(costoScVOp);

              this.resumen[i].costoScFvOp = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoScFvOp
              );

              if (this.resumen[i].cant_horas != 0) {
                this.resumen[i].turnScOp = parseFloat(
                  this.resumen[i].tot_tiempo_operativo /
                    60 /
                    this.resumen[i].cant_horas
                );
              } else {
                this.resumen[i].turnScOp = 0;
              }

              this.resumen[i].turnScOp = await this.calculoTurn(
                this.resumen[i].turnScOp
              );

              costoScOp = this.resumen[i].tarifa_op * this.resumen[i].turnScOp;

              this.resumen[i].costoScOp = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoScOp
              );

              if (this.resumen[i].cant_horas != 0) {
                this.resumen[i].turnScDisp = parseFloat(
                  this.resumen[i].tot_tiempo_disponible /
                    60 /
                    this.resumen[i].cant_horas
                );
              } else {
                this.resumen[i].turnScDisp = 0;
              }

              this.resumen[i].turnScDisp = await this.calculoTurn(
                this.resumen[i].turnScDisp
              );

              costoScDisp =
                this.resumen[i].tarifa_disp * this.resumen[i].turnScDisp;

              this.resumen[i].costoScDisp = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoScDisp
              );

              costoTotSc += parseFloat(costoScOp) + parseFloat(costoScDisp);
              this.costoTotSc = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTotSc
              );

              costoTotFvSc += parseFloat(costoScFvOp);
              this.costoTotFvSc = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTotFvSc
              );
            }
          }

          let totalLiqui =
            parseFloat(costoTotTm) +
            parseFloat(costoTotDt) +
            parseFloat(costoTotSc);

          let totalLiquiFv =
            parseFloat(costoTotFvTm) +
            parseFloat(costoTotFvDt) +
            parseFloat(costoTotFvSc);

          this.totalLiqui = this.formatCurrency("es-CO", "COP", 2, totalLiqui);
          this.totalLiquiFv = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            totalLiquiFv
          );
        });

      this.totalDet.fracTotal = 0;
      this.totalDet.fracTiemTotal = 0;
      this.totalDet.horaTotal = 0;
      this.totalDet.horaTiemTotal = 0;

      for (let x = 0; x < this.detLiquidaciones.length; x++) {
        let costoOperativo = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.detLiquidaciones[x].valor_operativo
        );

        let costoDisponible = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.detLiquidaciones[x].valor_disponible
        );

        let objval = {
          costoFijo: this.detLiquidaciones[x].valor_fijo,
          costoVariable: this.detLiquidaciones[x].valor_variable,
          costoTotal: this.detLiquidaciones[x].valor_total,
          costoOperativo,
          costoDisponible,
        };

        this.valores.push(objval);

        this.detLiquidaciones[x].valorFijo = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.detLiquidaciones[x].valor_fijo
        );

        this.detLiquidaciones[x].valorVariable = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.detLiquidaciones[x].valor_variable
        );

        this.detLiquidaciones[x].valorTotal = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.detLiquidaciones[x].valor_total
        );

        this.totalDet.horaOp = parseFloat(this.totalDet.horaOp);
        this.totalDet.horaIn = parseFloat(this.totalDet.horaIn);

        this.valorTarFijo = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.totalDet.valorTarFijo
        );

        this.valorTotOp = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.totalDet.valorTotOp
        );

        this.valorTotDis = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.totalDet.valorTotDis
        );

        this.valorTarVar = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.totalDet.valorTarVar
        );

        this.detLiquidaciones[x].fracDetTotalTm =
          (parseFloat(this.detLiquidaciones[x].tiempo_operativo / 60) +
            parseFloat(this.detLiquidaciones[x].tiempo_disponible / 60) +
            parseFloat(this.detLiquidaciones[x].tiempo_indisponible / 60)) /
          parseFloat(this.detLiquidaciones[x].turno.dia_turno_one.cant_horas);

        this.detLiquidaciones[x].fracDetTotalFv =
          (parseFloat(this.detLiquidaciones[x].tiempo_operativo / 60) +
            parseFloat(this.detLiquidaciones[x].tiempo_indisponible / 60)) /
          parseFloat(this.detLiquidaciones[x].turno.dia_turno_one.cant_horas);

        this.totalDet.fracTotal += parseFloat(
          this.detLiquidaciones[x].fracDetTotalFv
        );

        this.totalDet.fracTiemTotal += parseFloat(
          this.detLiquidaciones[x].fracDetTotalTm
        );

        this.totalDet.horaTotal +=
          parseFloat(this.detLiquidaciones[x].tiempo_operativo / 60) +
          parseFloat(this.detLiquidaciones[x].tiempo_indisponible / 60);

        this.totalDet.horaTiemTotal +=
          parseFloat(this.detLiquidaciones[x].tiempo_operativo / 60) +
          parseFloat(this.detLiquidaciones[x].tiempo_disponible / 60) +
          parseFloat(this.detLiquidaciones[x].tiempo_indisponible / 60);

        this.fracValorTotal = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          this.totalDet.fracValorTotal
        );
      }
    },

    async calculoTurn(num) {
      let res = 0;
      await axios
        .get("/api/tif/detLiquidaciones/calculoTurnos", {
          params: {
            numero: num,
          },
        })
        .then(async (response) => {
          res = await response.data;
        });
      return res;
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    llenarModalDetPlaca(liqui, vehiculo_id, turno_id, tipo_vehiculo_id) {
      this.$refs.TifLiquidacionesDetPlaca.llenar_modal_detPlaca(
        liqui,
        vehiculo_id,
        turno_id,
        tipo_vehiculo_id
      );
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getEstados();
    await this.getBloques();
    await this.getEmpresas();
    await this.getTipoTarifa();
  },
};
</script>
